<template>
  <div class="login-container">
    <validation-observer ref="observer">
      <v-form class="login-form" @submit.prevent="sendRecoveryInfo">
        <h2 class="login__title">
          Password Recover
        </h2>
        <validation-provider
          v-slot="{ errors }"
          name="email"
          rules="required|email|max:255"
        >
          <text-field
            v-model="formData.email"
            class="mb-3"
            outlined
            placeholder="Email address"
            type="email"
            :error-messages="errors"
          />
        </validation-provider>
        <primary-button
          class="mb-5"
          full-width
          :loading="loading"
          type="submit"
        >
          Reset password
        </primary-button>
        <span v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </span>
      </v-form>
    </validation-observer>
    <v-divider />
    <router-link to="/login" class="recover-link py-6">
      Back to Sign In
    </router-link>
  </div>
</template>

<script>
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import TextField from '@/components/ui/TextField/TextField';
import store from '@/store';

export default {
  name: 'PasswordRecover',
  components: { TextField, PrimaryButton },
  beforeRouteEnter: (to, from, next) => {
    if (store.state.auth.isAuthorized) {
      next({
        name: 'Activity',
        params: {
          workspace: store.state.publicStore.selectedWorkspace.slug,
        },
      });
    }

    next();
  },
  data() {
    return {
      loading: false,
      formData: {
        email: '',
      },
    };
  },

  computed: {
    errorMessage() {
      return this.$store.getters.getErrorMsg;
    },
  },
  created() {
    this.$store.commit('updateErrorMsg', null);
  },
  methods: {
    sendRecoveryInfo() {
      const observerRef = this.$refs.observer;

      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.loading = true;
          this.$store.dispatch('RECOVERY_PASSWORD', { ...this.formData })
            .then(() => {
              this.loading = false;
            }).finally(() => {
              this.formData.email = '';
              observerRef.reset();
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .input-group {
    max-width: unset;
  }
  .login-container {
    min-height: 369px;
  }
  .error-message {
    text-align: center;
    display: block;
    color: $error;
    margin-bottom: 20px;
  }
</style>
